//based on loves.com and loves branding 2022
@import url('https://fonts.googleapis.com/css?family=Muli');

/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";


@import "@progress/kendo-theme-bootstrap/dist/all.scss";

// If you want to add something do it here
@import "custom";